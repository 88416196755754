import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  CardBodyText, CardContainer, RelativeContainer,
} from '../Card';
import Headline5 from '../Typography/Heading/Headline5';
import { mediumRoundedCorners } from '../RoundedCorners';

const AdaptiveCardContainer = styled(CardContainer)<
{ textAlign?: 'center' | 'left' | 'right' }>`
  ${mediumRoundedCorners};
  position: relative;
  filter: drop-shadow(0 0 8px var(--black-disabled));
  background-color: white;
  padding: 0 0 16px;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  text-align: ${({ textAlign }) => textAlign ?? 'center'};

  :hover {
    transform: scale(1.02);
  }
`;

const LogoImageContainer = styled(RelativeContainer)`
  display: flex;
  align-items: center;
  padding-bottom: 0;
  height: 60px;
  width: 200px;
  margin: 20px auto 12px;

  && progressive-img, img {
    height: 60px;
    --img-height: 60px;
  }
  && img {
    width: 60px;
  }
`;

const TextContainer = styled.div`
  height: 3rem;
  padding: 0 24px 24px;
  img {
    display: none;
  }
`;

const TitleContainer = styled.div`
  margin: 1.25rem 1.5rem .75rem;
`;

const adaptiveCardPropertyTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  image: PropTypes.node,
  logo: PropTypes.node,
  aspectRatioPadding: PropTypes.string,
};

type AdaptiveCardProperties = InferProps<typeof adaptiveCardPropertyTypes> & {
  aspectRatioPadding?: string;
  textAlign?: 'center' | 'left' | 'right';
};

const AdaptiveCard = ({
  title,
  image,
  logo,
  description,
  aspectRatioPadding,
  textAlign,
} : AdaptiveCardProperties) => (
  <AdaptiveCardContainer textAlign={textAlign}>
    <RelativeContainer
      isCardImage
      aspectRatioPadding={aspectRatioPadding}
    >
      { image }
    </RelativeContainer>
    {logo ? (
      <LogoImageContainer>
        {logo}
      </LogoImageContainer>
    ) : (
      <TitleContainer>
        <Headline5
          tag="span"
        >
          { title }
        </Headline5>
      </TitleContainer>
    )}
    {description && (
      <TextContainer>
        <CardBodyText>{ description }</CardBodyText>
      </TextContainer>
    )}
  </AdaptiveCardContainer>
);

AdaptiveCard.propTypes = adaptiveCardPropertyTypes;

export default AdaptiveCard;
